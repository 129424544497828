<template>
         <section class=" mt-10 solutions-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm0 class="mt-10">
                        <v-card flat tile color="" width="90%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center mt-10">
                                    <h3 class="zamu-heading transact-heading product-heading solutions-heading">Login</h3><br />
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-card-text align="center" justify="center">
                                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                                    {{ notification }}
                                </v-alert>
                                 <v-form ref="loginForm" class="loginForm" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginEmail" :rules="loginEmailRules" label="E-mail" required outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field outlined dens v-model="loginPassword" :append-icon="show1?'eye':'eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="6" xsm="12">
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn large block :disabled="!valid" :color="`${zamuPurple2}`" @click="validate" dark> Login </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Login',
    mixins: [colorMixin, coreMixin],
      data () {
      return {
        dialog: false,
            tab: 0,
            loading: false,
            tabs: [
                {name:"Sign In", icon:"mdi-account"},
            ],
            valid: true,
            notification: "",
            actionClass: "",
            
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            verify: "",
            loginPassword: "",
            loginEmail: "",
            loginEmailRules: [
                v => !!v || "Email is Required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            emailRules: [
                v => !!v || "Email is Required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],

            show1: false,
            rules: {
            required: value => !!value || "Password is Required.",
            min: v => (v && v.length >= 4) || "Min 4 characters"
            }
      };
    },
    computed: {
        ...mapState(['token']),
        ...mapGetters(['isAuthenticated']),
        passwordMatch() {
            return () => this.password === this.verify || "Password must match";
        }
    },
    mounted () {
        localStorage.clear();
    },
    methods: {
    ...mapMutations(['setAccessToken']),

       async validate() {
            if (this.$refs.loginForm.validate()) {
                // submit form to server/API here...
                this.loading = true;

               const payload = {
                    email: this.loginEmail,
                    password: this.loginPassword
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'auth/login',
                };
                  try {
                    const res = await this.axiosPostRequest(fullPayload);
                    this.notification = res.status === 200 ? 'Login successful' : res.data.message;
                    const token = res.data.token;

                    this.setAccessToken(token);

                    localStorage.setItem('jwtToken', token);

                    this.actionClass = res.status === 200 ? 'success' : 'error';
                    this.loading = false;
                    return res.status === 200 ? this.$router.push('/admin') : '';

                } catch (error) {
                    this.notification = 'Login failed. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        }
    },


}
</script>

<style>

</style>